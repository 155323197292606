import { FranchiseSalesDashboardService } from '../../../services/modules/franchiseSalesDashboard.service';

export default {
	namespaced: true,

    // state
    state: {
        // card values
        startUpSalesLastYear: 0,
        startUpSalesLastYearYTD: 0,
        startUpSalesCurrentYear: 0,

        expansionSalesLastYear: 0,
        expansionSalesLastYearYTD: 0,
        expansionSalesCurrentYear: 0,

        conversionSalesLastYear: 0,
        conversionSalesLastYearYTD: 0,
        conversionSalesCurrentYear: 0,

        satelliteSalesLastYear: 0,
        satelliteSalesLastYearYTD: 0,
        satelliteSalesCurrentYear: 0,

        activeFSConsultantsLastYear: 0,
        activeFSConsultantsCurrentYear: 0,

        applicationTypeNOCurrentYear: 0,
        applicationTypeNOPreviousYear: 0,
        applicationTypeNOPreviousYearYTD: 0,

        // Regions overview
        regionsOverview: [],

        // Sales overview
        salesOverview: [],

        // FS Consultants performance overview
        fsConsultantsPerformanceOverview: [],

        // checkboxes
        activeChecked: false,
        inactiveChecked: false,
        onHold: false,
        onHoldOverDue: false,

        // regions dropdown value
        selectedRegionNames: null,

        selectedRegionIDs: [String(JSON.parse(localStorage.getItem('userInfo'))?.regionID)],

        // contract type dropdown
        selectedContractType: null,

        // FS consultant
        selectedFSConsultant: null,

        contractProcessedFromDate: null,
        contractProcessedToDate: null,

        // options for contract types
        contractOptions: [
            { label: 'Start up Sales', value: '6' },
            { label: 'Expansion Sale', value: '7' },
            { label: 'Conversion', value: '5' },
            { label: 'Satellites', value: '8' },
            { label: 'No', value: '1' },
        ],

        // options for the FS consultants
        consultantsList: [], 

        allOffices: [],

        loggedInUser: null,

        updateTriggeredAndIsAllowed: false,

        activeOfficeCountCurrentYear: 0,
        activeOfficeCountPreviousYear: 0,
        activeOfficeCountPreviousYearDataYtd: 0,
        activeOfficeCountBusinessPlanYearEnd: 0,
        totalOfficesBP: 0,

        salesProcessedCurrentYear: [],
        salesProcessedPreviousYear: [],
        salesProcessedPreviousYearYTD: [],
        salesProcessedBusinessPlanYearEnd: [],
        salesProcessedBP: [],

        officeTerminationsCurrentYear: [],
        officeTerminationsPreviousYear: [],
        officeTerminationsPreviousYearYTD: [],
        officeTerminationsBusinessPlanYearEnd: [],
        officeTerminationsBP: [],

        renwalsCurrentYear: [],
        renwalsPreviousYear: [],
        renwalsPreviousYearYTD: [],
        renwalsUpComingCY: [],

        netGrowthCurrentYear: [],
        netGrowthPreviousYear: [],
        netGrowthPreviousYearYTD: [],
        netGrowthBusinessPlanYearEnd: [],
        netGrowthBP:[]
    },

    // mutations
    mutations: {
        setState(state, partialState) {
            const partial = JSON.parse(JSON.stringify(partialState));
            Object.keys(partial).forEach((key) => { state[key] = partial[key]; });
        },

        updateConsultant(state, payload) {
            state.selectedFSConsultant = payload
        },

    },
    getters: {
        getSalesOverviewData(state) {
            return state.salesOverview 
        }
    },

    // actions
    actions: {
        async getUnrestrictedData({ state, commit }) {
            // make request
            const { result } = await FranchiseSalesDashboardService.getUnrestrictedData(state.selectedRegionIDs, state.selectedFSConsultant)
            
            // method to save the sales results into state
            const saveData = (data, saveKey) => state[saveKey] = data['office_count']

            // error checking
            if (!result.getActiveFSConsultantsOverview.successFlag) console.warn('getUnrestrictedData() -> getActiveFSConsultantsOverview', result.getActiveFSConsultantsOverview.successFlag);
            if (!result.getFsConsultantsOverview.successFlag) console.warn('getUnrestrictedData() -> getFsConsultantsOverview', result.getFsConsultantsOverview.successFlag);
            if (!result.getRegionsOverview.successFlag) console.warn('getUnrestrictedData() -> getRegionsOverview', result.getRegionsOverview.successFlag);
            if (!result.getSalesData.successFlag) console.warn('getUnrestrictedData() -> getSalesData', result.getSalesData.successFlag);
            if (!result.getFsConsultants.successFlag) console.warn('getRestrictedData() -> getFsConsultants', result.getFsConsultants.successFlag);

            // data results pulled out of object structure
            const activeFSConsultantsOverview = result.getActiveFSConsultantsOverview.data.result
            const fsConsultantsOverview = result.getFsConsultantsOverview.data.result
            const regionsOverview = result.getRegionsOverview.data.result
            const salesData = result.getSalesData.data.result
            const fsConsultants = result.getFsConsultants.data.result
            
            // reset values
            state.startUpSalesLastYear = 0
            state.startUpSalesLastYearYTD = 0
            state.startUpSalesCurrentYear = 0
            state.conversionSalesLastYear = 0
            state.conversionSalesLastYearYTD = 0
            state.conversionSalesCurrentYear = 0
            state.satelliteSalesLastYear = 0
            state.satelliteSalesLastYearYTD = 0
            state.satelliteSalesCurrentYear = 0
            state.expansionSalesLastYear = 0
            state.expansionSalesLastYearYTD = 0
            state.expansionSalesCurrentYear = 0
            state.applicationTypeNOCurrentYear = 0
            state.applicationTypeNOPreviousYear = 0
            state.applicationTypeNOPreviousYearYTD = 0
            
            // save to state
            commit('setState', { 
                // save getRegionsOverview response
                regionsOverview: regionsOverview,

                // save getFsConsultantsOverview response
                fsConsultantsPerformanceOverview: fsConsultantsOverview,

                // save getActiveFSConsultantsOverview response
                activeFSConsultantsCurrentYear: activeFSConsultantsOverview.currentYear, 
                activeFSConsultantsLastYear: activeFSConsultantsOverview.previousYearData,

                // fsConsultants dropdown
                consultantsList: fsConsultants.reduce((_acc, data, index) => {
                    _acc.push({ label: data.full_name, regionID: data?.region_id, value: String(data.id) })
                    return _acc
                }, []),
            });

            // save all current year sales results
            salesData["currentYear"].forEach((data) => {
                switch (data['ApplicationType']) {
                    case "Start up Sales": return saveData(data, 'startUpSalesCurrentYear')
                    case "Conversion": return saveData(data, 'conversionSalesCurrentYear')
                    case "Expansion Sale": return saveData(data, 'expansionSalesCurrentYear')
                    case "Satellite": return saveData(data, 'satelliteSalesCurrentYear')
                    case "No": return saveData(data, 'applicationTypeNOCurrentYear')
                }                
            })

            // save all previous year sales results
            salesData["previousYearData"].forEach((data) => {
                switch (data['ApplicationType']) {
                    case "Start up Sales": return saveData(data, 'startUpSalesLastYear')
                    case "Conversion": return saveData(data, 'conversionSalesLastYear')
                    case "Expansion Sale": return saveData(data, 'expansionSalesLastYear')
                    case "Satellite": return saveData(data, 'satelliteSalesLastYear')
                    case "No": return saveData(data, 'applicationTypeNOPreviousYear')
                }                
            })
            // save all previous year to date results
            salesData["lastYearYtdData"].forEach((data) => {
                switch (data['ApplicationType']) {
                    case "Start up Sales": return saveData(data, 'startUpSalesLastYearYTD')
                    case "Conversion": return saveData(data, 'conversionSalesLastYearYTD')
                    case "Expansion Sale": return saveData(data, 'expansionSalesLastYearYTD')
                    case "Satellite": return saveData(data, 'satelliteSalesLastYearYTD')
                    case "No": return saveData(data, 'applicationTypeNOPreviousYearYTD')
                }                
            })

                return {
                    startUpSalesLastYear: state.startUpSalesLastYear,
                    startUpSalesLastYearYTD: state.startUpSalesLastYearYTD,
                    startUpSalesCurrentYear: state.startUpSalesCurrentYear,
                    conversionSalesLastYear: state.conversionSalesLastYear,
                    conversionSalesLastYearYTD: state.conversionSalesLastYearYTD,
                    conversionSalesCurrentYear: state.conversionSalesCurrentYear,
                    satelliteSalesLastYear: state.satelliteSalesLastYear,
                    satelliteSalesLastYearYTD: state.satelliteSalesLastYearYTD,
                    satelliteSalesCurrentYear: state.satelliteSalesCurrentYear,
                    expansionSalesLastYear: state.expansionSalesLastYear,
                    expansionSalesLastYearYTD: state.expansionSalesLastYearYTD,
                    expansionSalesCurrentYear: state.expansionSalesCurrentYear,
                    applicationTypeNOCurrentYear: state.applicationTypeNOCurrentYear,
                    applicationTypeNOPreviousYear: state.applicationTypeNOPreviousYear,
                    applicationTypeNOPreviousYearYTD: state.applicationTypeNOPreviousYearYTD
                };
        },

        async getRestrictedData({ state, commit }) {
            // make request
            const { result } = await FranchiseSalesDashboardService.getRestrictedData(state.selectedRegionIDs, state.selectedFSConsultant)
            
            if (!result.getActiveOfficeCount.successFlag) console.warn('getRestrictedData() -> getActiveOfficeCount', result.getActiveOfficeCount.successFlag);
            if (!result?.getFsConsultants?.successFlag) console.warn('getRestrictedData() -> getFsConsultants', result?.getFsConsultants?.successFlag);
            if (!result.getNetGrowth.successFlag) console.warn('getRestrictedData() -> getNetGrowth', result.getNetGrowth.successFlag);
            if (!result.getOfficeTerminations.successFlag) console.warn('getRestrictedData() -> getOfficeTerminations', result.getOfficeTerminations.successFlag);
            if (!result.getRenewals.successFlag) console.warn('getRestrictedData() -> getRenewals', result.getRenewals.successFlag);
            if (!result.getSalesOverview.successFlag) console.warn('getRestrictedData() -> getSalesOverview', result.getSalesOverview.successFlag);
            if (!result.getSalesProcessed.successFlag) console.warn('getRestrictedData() -> getSalesProcessed', result.getSalesProcessed.successFlag);

            const activeOfficeCount = result.getActiveOfficeCount.data.result
            const salesProcessed = result.getSalesProcessed.data.result
            const fsConsultants = result?.getFsConsultants?.data?.result || []
            const netGrowth = result.getNetGrowth.data.result
            const officeTerminations = result.getOfficeTerminations.data.result
            const renewals = result.getRenewals.data.result
            const salesOverview = result.getSalesOverview.data.result

             // save response
             commit('setState', {
                // active office count
                totalOfficesBP: activeOfficeCount.businessPlan,
                activeOfficeCountCurrentYear: activeOfficeCount.currentYear,
                activeOfficeCountPreviousYear: activeOfficeCount.previousYearData,
                activeOfficeCountPreviousYearDataYtd: activeOfficeCount.previousYearDataYtd,
                activeOfficeCountBusinessPlanYearEnd: activeOfficeCount.businessPlanYearEnd,

                // sales processed
                salesProcessedBP: salesProcessed.businessPlan,
                salesProcessedCurrentYear: salesProcessed.currentYear,
                salesProcessedPreviousYear: salesProcessed.previousYear,
                salesProcessedPreviousYearYTD: salesProcessed.previousYearYtd,
                salesProcessedBusinessPlanYearEnd: salesProcessed.businessPlanYearEnd,

                salesOverview: salesOverview.map((sale) => ({ ...sale, fee_approval: (sale.fee_approval == 'not_applicable')? 'not applicable' : sale.fee_approval})),
                // renewals
                renwalsCurrentYear: renewals.currentYear,
                renwalsPreviousYear: renewals.previousYear,
                renwalsPreviousYearYTD: renewals.previousYearYtd,
                renwalsUpComingCY: renewals.upcomingCy,

                // officeTerminations
                officeTerminationsCurrentYear: officeTerminations.currentYear,
                officeTerminationsPreviousYear: officeTerminations.previousYear,
                officeTerminationsPreviousYearYTD: officeTerminations.previousYearYtd,
                officeTerminationsBusinessPlanYearEnd: officeTerminations.businessPlanYearEnd,
                officeTerminationsBP: officeTerminations.businessPlan,

                // netGrowth
                netGrowthCurrentYear: netGrowth.currentYear,
                netGrowthPreviousYear: netGrowth.previousYear,
                netGrowthPreviousYearYTD: netGrowth.previousYearYtd,
                netGrowthBusinessPlanYearEnd: netGrowth.businessPlanYearEnd,
                netGrowthBP: netGrowth.businessPlan

            })
        },

        async getOffices({ state, commit }) {
            // make request
            const { result, message } = await FranchiseSalesDashboardService.getOffices()
            
            // validate data came back
            if (result.length == 0 || result.length == undefined) { console.warn('getOffices() -> ', message); return}


            const status = { inActive: [], active: [], onHold: [], onHoldOverdue: [] }
            result.forEach(office => {
                if (office.region_id == state.loggedInUser.regionID) switch (office.office_status) {
                    case 0: return status["inActive"].push(office);
                    case 1: return status["active"].push(office);
                    case 2: return status["onHold"].push(office);
                    case 4: return status["onHoldOverdue"].push(office);
                }
            })
            console.log(`Office Status Count for region (${state.loggedInUser.regionID}) ->`, status);
            

            // save response
            commit('setState', { allOffices: result })
            
        }

    },
}
